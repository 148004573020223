<template>
  <div>
    <div class="container">
      <div class="wrapper">
        <div class="main-card">
          <div class="card-1">
            <img src="../assets/images/logo.jpg" alt="" class="main-logo">
            <h1 class="name">Vyacheslav</h1>
            <h3 class="subname">@flyare</h3>
            <div class="text">beginner front-end developer & designer</div>
          </div>
          <div class="card-3">
            <h2 class="">Information</h2>
            <ul class="">
              <li class="info-li"><i class="fa-solid fa-location-dot icon-li"></i> Moscow, Russia</li>
              <li class="info-li"><i class="fa-solid fa-cake-candles icon-li"></i> 11th May</li>
              <li class="info-li"><i class="fa-solid fa-wand-magic-sparkles icon-li"></i> Genshin Impact Player: <span class="code">740346576</span></li>
              <li class="info-li"><i class="fa-solid fa-briefcase icon-li"></i> Shuoki Team Co-Founder</li>
              <li class="info-li"><i class=" fa-solid fa-hammer-crash icon-li"></i> Ex BotiCord Server Moderator</li>
            </ul>
          </div>
          <div class="card-2">
            <h2 class="">Links</h2>
            <div class="contact-container">
              <a href="https://discord.com/users/561813937352343572" class="contact"><i class="fa-brands fa-discord link-icon"></i></a>
              <a href="https://t.me/pathme" class="contact"><i class="fa-solid fa-paper-plane link-icon"></i></a>
              <a href="mailto:flyare@shuoki.top" class="contact"><i class="fa-solid fa-envelope link-icon"></i></a>
              <a href="https://www.twitch.tv/flyare_" class="contact"><i class="fa-brands fa-twitch link-icon"></i></a>
              <a href="https://www.youtube.com/channel/UCuc4vXi955OKsRIjFexYDVA" class="contact"><i class="fa-brands fa-youtube link-icon"></i></a>
              <!-- <a href="" class="contact"><i class="fa-brands fa-pinterest-p link-icon"></i></a> -->
            </div>
            <div class="donate-container">
              <a href="https://qiwi.com/n/FLYARE" class="donate-btn">Donate <i class="fa-solid fa-circle-dollar-to-slot donate-icon"></i></a>
              <a href="https://github.com/flyare1337" class="donate-btn">GitHub <i class="fa-brands fa-github donate-icon"></i></a>
              <!-- <a href="" class="donate-btn">BotiCord <i class="fa-solid fa-list-dropdown donate-icon"></i></a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  // props: {
  //   msg: String
  // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.container {
  /* height: 100vh; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.wrapper {
  width: 700px;
  padding: 40px 15px;
}

.main-card {
  /* margin: 40px 0; */
  /* max-width: 700px; */
  /* border-radius: 40px; */

  box-shadow: 0 25px 45px rgb(0,0,0,0.3);
  overflow: hidden;
  height: auto;
  width: 100%;
}





.card-1, .card-2, .card-3 {
  border: 2px solid rgb(255, 255, 255, 0.25);
  backdrop-filter: blur(10px);
  overflow: hidden;
  background: rgb(255, 255, 255, 0.1);
  /* backdrop-filter: blur(40px); */
  padding: 25px;
}
.card-1 {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  text-align: center;

}

.card-3 {
  margin-top: 5px;
}
.card-2 {
  margin-top: 5px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.main-logo {
  width: 120px;
  border-radius: 0.5rem;
}

.name {
  color: #b5dac4;
}

.subname {
  color: #f3f3f3;
}

.card-1 .text {
  padding-top: 5px;
}

.card-3 ul {
  list-style: none;
  padding: 0;
  margin: 15px 0;
  width: auto;
}

.info-li {
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon-li {
  width: 20px;
  color: #f1f1f1;
  font-size: 17px;
}

.code {
  font-size: 16px;
  background: rgb(0,0,0,0.2);
}

.contact-container {
  margin: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}

.contact {
  font-size: 28px;
  transition: all .25s ease;
  color: #fff;
  border-bottom: 2px solid transparent;
  padding-bottom: 5px;

}

.contact:hover {
  transform: translateY(-5px);
  color: #b5dac4;
  border-bottom: 2px solid #b5dac4;
}

.contact:active {
  transform: translateY(-5px) scale(0.900);

}

.donate-container {
  /* display: block; */
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;

}
.donate-btn {
  text-align: center;
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10rem;
  border: 2px solid rgb(255, 255, 255, 0.5);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  transition: all .125s ease;

}

.donate-btn:hover {
  /* transform: scale(1.025); */
  color: #b5dac4;
  border: 2px solid #b5dac4;
  background: rgb(181, 218, 196, 0.2);
}

.donate-btn:active {
  background: rgb(181, 218, 196, 0.3);

}

.donate-btn i {
  font-size: 20px;
}


</style>
