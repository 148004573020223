<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <HelloWorld/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>

@font-face {
  font-family: "Museo Sans";
  src: url(/src/assets/fonts/MuseoSansCyrl.otf);
}
body {
  color: #dddddd;
  background: url(/src/assets/images/bg4.jpg) no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-color: #000;
  /* backdrop-filter: blur(4px); */
  font-family: "Museo Sans";
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

h1, h2, h3 {
  padding: 0;
  margin: 0;
  color: #f3f3f3;
  text-align: center;

}

a {
  text-decoration: none;
  color: #dddddd;
  margin: 0;
  padding: 0;
}
</style>
